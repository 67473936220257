<template>
  <div class="organizer-container content-container">
    <div class="organizer-tree left-container">
      <el-input
        placeholder="输入组织名称搜索"
        v-model="organizeName"
        @keydown.enter="handleSearchTree"
      >
        <template #suffix>
          <i class="iconfont icon-a-lianhe76 search-icon"></i>
        </template>
      </el-input>
      <el-tree
        :data="organizeData"
        :props="defaultProps"
        :highlight-current="true"
        node-key="id"
        ref="tree"
        id="tree"
        @node-click="handleNodeClick"
        :default-expanded-keys="[defaultId]"
        :default-checked-keys="[defaultId]"
        :check-strictly="true"
        accordion
      >
        <template #default="{ node }">
          <i
            class="iconfont"
            :class="
              ['', 'icon-a-zu4763', 'icon-a-lujing495', 'icon-a-zu4764'][
                node.level
              ]
            "
          ></i>
          <span>{{ node.label }}</span>
        </template>
      </el-tree>
    </div>
    <div class="right-container">
      <ContentTitle>{{ selectDepartment.departmentName }}</ContentTitle>
      <Wfilter
        :filterConfig="filterConfig"
        @openOrganizationDialog="openOrganizationDialog"
        @openMemberDialog="openMemberDialog"
        @handleSearch="handleSearch"
        @print="handlePrint"
        @export="handleExport"
      ></Wfilter>
      <template v-if="selectDepartment.departmentId">
        <template v-if="organizerTableData.length">
          <Wtable
            :columns="organizerColumns"
            :pagination="pagination"
            :tableData="organizerTableData"
            @currentChange="currentChange"
            @sizeChange="sizeChange"
            @updateOrganizer="openOrganizationDialog"
          >
          </Wtable>
        </template>
        <template v-if="tableData.length">
          <Wtable
            :columns="columns"
            :pagination="pagination"
            :tableData="tableData"
            @currentChange="currentChange"
            @sizeChange="sizeChange"
            @resetPassword="openResetPasswordDialog"
            @update="openMemberDialog"
          >
            <template #status="{ row }">
              <el-switch
                @click="changeStatus(row)"
                :disabled="
                  canChangeStatus == 0 || row.super_admin == 1 ? true : false
                "
                v-model="row.status"
                inline-prompt
                active-text="开启"
                inactive-text="关闭"
              >
              </el-switch>
            </template>
          </Wtable>
        </template>
      </template>
      <template v-if="!organizerTableData.length && !tableData.length">
        <div class="organizer-empty">
          <el-empty description="暂无数据"></el-empty></div
      ></template>
    </div>
    <Wdialog
      ref="organizationDialog"
      :title="organizationTitle"
      width="40%"
      @wConfirm="handleAddOrganization"
    >
      <el-form
        ref="organizationFormRef"
        :model="organizationFormData"
        :rules="organizationFormRules"
        label-position="top"
        class="organization-form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="name" label="组织名称">
              <el-input
                v-model="organizationFormData.name"
                autocomplete="off"
                placeholder="请输入企业组织名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="pid" label="上级组织">
              <el-cascader
                v-model="organizationFormData.pid"
                clearable
                placeholder="请选择上级组织"
                :options="superiorOptions"
                :disabled="organizationFormData.id ? true : false"
                :props="{
                  expandTrigger: 'hover',
                  value: 'id',
                  label: 'name',
                  children: 'child',
                  checkStrictly: true,
                }"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="sort" label="排序">
              <el-input
                v-model="organizationFormData.sort"
                autocomplete="off"
                type="number"
                min="0"
                placeholder="请输入排序"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="addr" label="组织地址">
              <el-input
                v-model="organizationFormData.addr"
                autocomplete="off"
                placeholder="请输入组织地址"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
    <Wdialog
      ref="memberDialog"
      :title="memberTitle"
      width="40%"
      @wConfirm="handleSureMember"
    >
      <el-form
        ref="memberFormRef"
        :model="memberFormData"
        :rules="memberFormRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="realname" label="成员姓名">
              <el-input
                v-model="memberFormData.realname"
                autocomplete="off"
                placeholder="请输入成员姓名"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="department_id" label="所属组织">
              <el-cascader
                v-model="memberFormData.department_id"
                clearable
                placeholder="请选择所属组织"
                :options="organisationOptions"
                :props="{
                  expandTrigger: 'hover',
                  value: 'id',
                  label: 'name',
                  children: 'child',
                  emitPath: false,
                }"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="mobile" label="手机号码">
              <el-input
                v-model="memberFormData.mobile"
                autocomplete="off"
                placeholder="请输入手机号码"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="gender" label="员工性别">
              <el-select
                v-model="memberFormData.gender"
                placeholder="请选择员工性别"
                filterable
              >
                <el-option
                  v-for="(item, index) in sexOptions"
                  :key="index"
                  :label="item"
                  :value="index + ''"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="email" label="邮箱地址">
              <el-input
                v-model="memberFormData.email"
                autocomplete="off"
                placeholder="请输入邮箱地址"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="选择成员角色" prop="role_id">
            <el-checkbox-group
              v-model="memberFormData.role_id"
              :disabled="memberFormData.super_admin == 1 ? true : false"
            >
              <el-checkbox
                v-for="(item, index) in roleOptions"
                :key="index"
                :label="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-row>
      </el-form>
    </Wdialog>

    <Wdialog
      ref="resetPasswordDialog"
      title="重置密码"
      width="30%"
      @wConfirm="handleResetPassword"
    >
      <el-form
        ref="resetPasswordFormRef"
        :model="resetPasswordFormData"
        :rules="resetPasswordFormRules"
        label-position="top"
        class="organization-form"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="newPassword" label="新密码">
              <el-input
                v-model="resetPasswordFormData.newPassword"
                autocomplete="off"
                placeholder="请输入新密码"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>

<script>
import { ref, onMounted, watch, reactive, computed, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  print,
  exportExcel,
  getTopBtnAuth,
  getBtnAuth,
} from "@/utils/common.js";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import API from "@/plugins/api.js";
import config from "./index.json";

export default {
  components: {
    Wtable,
    Wdialog,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const defaultId = ref(0);
    const defaultProps = {
      children: "child",
      label: "name",
    };
    const selectDepartment = ref({});
    const filterConfig = reactive(config.filterConfig);
    const sexOptions = ref([]);
    const organizeData = ref([]);

    function getElementByAttr(tag, dataAttr, reg) {
      const aElements = document.getElementsByTagName(tag);
      let aEle = [];
      for (let i = 0; i < aElements.length; i++) {
        const ele = aElements[i].getAttribute(dataAttr);
        if (reg == ele) {
          aEle.push(aElements[i]);
        }
      }
      return aEle;
    }
    function getDepTree(parmas) {
      API.getDepTree(parmas).then((res) => {
        organizeData.value = res;
        if (res.length) {
          if (res[0].child && res[0].child.length) {
            if (!selectDepartment.value.departmentId) {
              selectDepartment.value.departmentName = res[0].child[0].name;
              selectDepartment.value.level = 2;
              selectDepartment.value.departmentId = res[0].child[0].id;
              defaultId.value = res[0].child[0].id;
              nextTick(() => {
                const divs = getElementByAttr(
                  "div",
                  "data-key",
                  res[0].child[0].id
                );
                divs.length ? (divs[0].className += " is-current") : "";
              });
            }
          }
        }
      });
    }
    const roleOptions = ref([]);
    onMounted(() => {
      API.getGenderOptions().then((res) => {
        sexOptions.value = res;
      });

      getDepTree();
    });
    const organizeName = ref("");

    function handleSearchTree() {
      getDepTree({
        name: organizeName.value,
      });
    }
    const tree = ref(null);
    function handleNodeClick(node, data) {
      let str = "";
      tree.value.setCheckedNodes([data]);
      if (data.level == 4) {
        str +=
          data.parent.parent.parent.data.name +
          "-" +
          data.parent.parent.data.name +
          "-" +
          data.parent.data.name +
          "-" +
          node.name;

        selectDepartment.value.level = 4;
      } else if (data.level == 3) {
        str +=
          data.parent.parent.data.name +
          "-" +
          data.parent.data.name +
          "-" +
          node.name;
        selectDepartment.value.level = 3;
      } else if (data.level == 2) {
        str += data.parent.data.name + "-" + node.name;
        selectDepartment.value.level = 2;
      } else if (data.level == 1) {
        str = node.name;
        selectDepartment.value.level = 1;
      }
      selectDepartment.value.departmentName = str;
      selectDepartment.value.departmentId = node.id;
      defaultId.value = node.id;
    }
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    const pagination = config.tableConfig.pagination;
    const columns = reactive(config.tableConfig.list);
    const defaultBtns = reactive(config.tableConfig.btns);
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns); // 获取有权限查看或操作的表格列
    const organizerColumns = reactive(config.tableConfig.organizerList);
    const organizerBtns = reactive(config.tableConfig.organizerBtns);
    organizerColumns[organizerColumns.length - 1].btns = getBtnAuth(
      rightBtn.value,
      organizerBtns
    ); // 获取有权限查看或操作的表格列

    const canChangeStatus = ref(0);

    // 获取权限按钮

    if (rightBtn.value && rightBtn.value.length) {
      rightBtn.value.forEach((el) => {
        if (el.api_menu_token == "Btn_qlviARKlwUdSa7V6YECg54hUot9O") {
          //修改用户状态
          canChangeStatus.value = 1;
        }
      });
    }
    const keywords = ref("");
    const tableData = ref([]);
    const organizerTableData = ref([]);
    function getUserData(parmas) {
      API.getUser({
        ...parmas,
        limit: pagination.pageSize,
        keywords: keywords.value,
        department_id: selectDepartment.value.departmentId,
      }).then((res) => {
        if (res.data.length) {
          res.data.forEach((el) => {
            el.showResetPassword = el.super_admin ? 0 : 1; //  不能修改超级管理员的密码
            if (el.status == 1) {
              el.status = true;
              el.status_str = "启用";
            } else {
              el.status = false;
              el.status_str = "未启用";
            }
          });
        }
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }

    async function fetchData(parmas) {
      await getUserData(parmas);
      API.getProjectByDepartmentSub({
        ...parmas,
        limit: pagination.pageSize,
        department_id: selectDepartment.value.departmentId,
      }).then((res) => {
        pagination.total = res.total;
        organizerTableData.value = res.data;
      });
    }
    const tableArr = computed(() => {
      return [tableData.value.length, organizerTableData.value.length];
    });
    const propertiesConfig = ref([]); //打印的数据
    watch(
      () => tableArr.value,
      (data) => {
        const organizationBtns = [
          {
            lable: "新增组织",
            icon: "icon-a-zu4785",
            action: "openOrganizationDialog",
            token: "Btn_IUj39zVLoZrffUBtWIalr1cvUGy1",
          },
        ];
        const memberBtns = [
          {
            lable: "新增成员",
            icon: "icon-a-zu4785",
            action: "openMemberDialog",
            token: "Btn_nLSOvnrJfa6mxKUWTQusXFbPW3vn",
          },
        ];
        const filterConfigIcons = [
          {
            icon: "icon-a-zu4759",
            action: "export",
            title: "导出",
          },
          {
            icon: "icon-a-zu4758",
            action: "print",
            title: "打印",
          },
        ];
        let filterConfigBtns = [];
        if (data[0] && !data[1]) {
          // 有人员 没有组织 新增人员
          filterConfigBtns = memberBtns;
          filterConfig.icons = filterConfigIcons;
          propertiesConfig.value = [
            { field: "realname", displayName: "姓名" },
            { field: "gender_text", displayName: "性别" },
            { field: "mobile", displayName: "手机号" },
            { field: "role_str", displayName: "角色" },
            { field: "status_str", displayName: "状态" },
          ];
        } else if (!data[0] && !data[1]) {
          filterConfig.icons = [];
          filterConfigBtns = [...organizationBtns, ...memberBtns];
        } else if (!data[0] && data[1]) {
          // 没有人员 有组织 新增组织
          filterConfigBtns = organizationBtns;
          filterConfig.icons = filterConfigIcons;
          propertiesConfig.value = [
            { field: "name", displayName: "组织名称" },
            { field: "sort", displayName: "排序" },
            { field: "user_count", displayName: "成员数量" },
            { field: "car_pack", displayName: "车场数量" },
            { field: "gate", displayName: "道闸数量" },
          ];
        }
        filterConfig.btns = getTopBtnAuth(rightBtn.value, filterConfigBtns);
      },
      {
        deep: true,
      }
    );

    watch(
      () => selectDepartment.value.departmentId,
      (v) => {
        let parmas = {
          department_id: v,
        };

        fetchData(parmas);
      },
      {
        deep: true,
      }
    );
    // watch(
    //   () => selectDepartment.value.level,
    //   (v) => {
    //     if ([3, 4].includes(v) && tableData.value.length) {
    //       filterConfig.search = {
    //         placeholderText: "搜索人员姓名、账号",
    //         action: "handleSearch",
    //       };
    //     } else {
    //       filterConfig.search = {};
    //     }
    //   },
    //   {
    //     deep: true,
    //   }
    // );

    const currentChange = (v) => {
      fetchData({
        page: v,
      });
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      const parmas = {
        department_id: selectDepartment.value.departmentId,
      };
      fetchData(parmas);
    };

    const handleSearch = (kwd) => {
      keywords.value = kwd;
      fetchData();
    };
    // 新增组织
    const organizationDialog = ref(null);
    const organizationFormRef = ref(null);
    const organizationFormRules = {
      name: [
        {
          required: true,
          message: "请输入企业组织名称",
          trigger: "blur",
        },
      ],
      pid: [
        {
          required: true,
          message: "请选择上级组织",
          trigger: "change",
        },
      ],
      sort: [
        {
          required: true,
          message: "请输入排序",
          trigger: "blur",
        },
      ],
    };
    const organizationFormData = ref({
      name: "",
      pid: [],
      sort: "",
      addr: "",
    });
    const superiorOptions = ref([]);
    function getSuperiorOptions(parmas) {
      API.getDepTree(parmas).then((res) => {
        superiorOptions.value = res;
      });
    }
    const organizationTitle = ref("");
    function openOrganizationDialog(data) {
      if (data && data.row) {
        organizationTitle.value = "编辑组织";
        API.geDepartmentDetail({ id: data.row.id }).then((res) => {
          organizationFormData.value = res;
          organizationFormData.value.pid = res.id;
        });
      } else {
        organizationTitle.value = "新增组织";
        organizationFormData.value = {
          name: "",
          pid: [],
          sort: "",
          addr: "",
        };
      }

      getSuperiorOptions({
        isAllShow: false,
      });
      organizationDialog.value.show();
    }

    function handleAddOrganization() {
      if (organizationFormRef.value) {
        organizationFormRef.value.validate((valid) => {
          if (valid) {
            let parmas = {
              name: organizationFormData.value.name,
              sort: organizationFormData.value.sort,
              addr: organizationFormData.value.addr,
            };
            if (organizationFormData.value.id) {
              //编辑

              parmas["id"] = organizationFormData.value.pid.length
                ? organizationFormData.value.pid[
                    organizationFormData.value.pid.length - 1
                  ]
                : organizationFormData.value.pid;
              API.editOrganization(parmas).then(() => {
                ElMessage.success({
                  message: "操作成功",
                });
                getDepTree();
                fetchData();
                organizationDialog.value.close();
              });
            } else {
              //新增
              (parmas["pid"] =
                organizationFormData.value.pid[
                  organizationFormData.value.pid.length - 1
                ]),
                API.addOrganization(parmas).then(() => {
                  ElMessage.success({
                    message: "操作成功",
                  });
                  getDepTree();
                  fetchData();
                  organizationDialog.value.close();
                });
            }
          }
        });
      }
    }
    // 新增或编辑成员
    const memberDialog = ref(null);
    const memberFormRef = ref(null);
    const memberFormRules = {
      realname: [
        {
          required: true,
          message: "请输入成员名称",
          trigger: "blur",
        },
      ],
      department_id: [
        {
          required: true,
          message: "请选择所属组织",
          trigger: "change",
        },
      ],
      mobile: [
        {
          required: true,
          message: "请输入手机号码",
          trigger: "blur",
        },
      ],
      role_id: [
        {
          type: "array",
          required: true,
          message: "请选择成员角色",
          trigger: "change",
        },
      ],
    };
    const memberFormData = ref({
      realname: "",
      department_id: "",
      email: "",
      mobile: "",
      role_id: [],
      gender: "",
    });
    const departmentOptions = ref([]);
    watch(
      () => memberFormData.value.department_id,
      (v) => {
        if (v) {
          API.getProjectByCompany({ department_id: v }).then((res) => {
            departmentOptions.value = res;
          });
        }
      },
      {
        deep: true,
      }
    );
    const memberTitle = ref("");
    const organisationOptions = ref([]);
    function getOrganisationOptions() {
      API.getDepTree().then((res) => {
        organisationOptions.value = res;
      });
    }
    function openMemberDialog(data) {
      API.getRoleOption({}).then((res) => {
        roleOptions.value = res;
      });
      if (data && data.row) {
        // 编辑
        memberTitle.value = "编辑人员";
        const rowData = JSON.parse(JSON.stringify(data.row));
        rowData.department_id = +rowData.department_id;
        memberFormData.value = rowData;
      } else {
        // 新增
        memberTitle.value = "新增人员";
        memberFormData.value = {
          realname: "",
          department_id: "",
          email: "",
          mobile: "",
          role_id: [],
          gender: "",
        };
      }
      getOrganisationOptions();

      memberDialog.value.show();
    }

    function handleSureMember() {
      if (memberFormRef.value) {
        memberFormRef.value.validate((valid) => {
          if (valid) {
            let parmas = {
              realname: memberFormData.value.realname,
              mobile: memberFormData.value.mobile,
              email: memberFormData.value.email,
              gender: memberFormData.value.gender,
              department_id: memberFormData.value.department_id,
              role: memberFormData.value.role_id,
            };
            if (memberFormData.value.id) {
              //编辑
              parmas["id"] = memberFormData.value.id;
              API.editMember(parmas).then((res) => {
                if (res) {
                  ElMessage.success({
                    message: "操作成功",
                  });
                  memberDialog.value.close();
                  fetchData();
                }
              });
            } else {
              //新增
              API.addMember(parmas).then((res) => {
                if (res) {
                  ElMessage.success({
                    message: "操作成功",
                  });
                  memberDialog.value.close();
                  fetchData();
                }
              });
            }
          }
        });
      }
    }

    //  重置密码
    const resetPasswordDialog = ref(null);
    const resetPasswordFormRef = ref(null);
    const resetPasswordFormData = ref({
      newPassword: "",
    });

    const resetPasswordFormRules = {
      newPassword: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
      ],
    };

    function openResetPasswordDialog(data) {
      resetPasswordFormData.value["id"] = data.row.id;
      resetPasswordDialog.value.show();
    }
    function handleResetPassword() {
      if (resetPasswordFormRef.value) {
        resetPasswordFormRef.value.validate((valid) => {
          if (valid) {
            API.resetPassword({
              password: resetPasswordFormData.value.newPassword,
              id: resetPasswordFormData.value.id,
            }).then((res) => {
              if (res) {
                ElMessage.success({
                  message: "操作成功",
                });
                resetPasswordDialog.value.close();
                fetchData();
              }
            });
          }
        });
      }
    }
    function changeStatus(row) {
      if (canChangeStatus.value) {
        if (row.super_admin) {
          ElMessage.warning({
            message: "不能修改系统管理员账号状态！",
          });
          return false;
        }
        const status = row.status == true ? 1 : 0;
        let parmas = {
          id: row.id,
          status: status,
        };
        API.editUserStatus(parmas).then(() => {
          ElMessage.success({
            message: "操作成功",
          });
          fetchData();
        });
      } else {
        ElMessage.warning({
          message: "暂无权限操作！",
        });
        return false;
      }
    }
    function handlePrint() {
      if (organizerTableData.value && organizerTableData.value.length) {
        print(
          selectDepartment.value.departmentName,
          organizerTableData.value,
          propertiesConfig.value
        );
      }
      if (tableData.value && tableData.value.length) {
        print(
          selectDepartment.value.departmentName,
          tableData.value,
          propertiesConfig.value
        );
      }
    }
    function handleExport() {
      try {
        if (organizerTableData.value && organizerTableData.value.length) {
          exportExcel(
            selectDepartment.value.departmentName,
            organizerTableData.value,
            propertiesConfig.value
          );
        }
        if (tableData.value && tableData.value.length) {
          exportExcel(
            selectDepartment.value.departmentName,
            tableData.value,
            propertiesConfig.value
          );
        }
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      handleSearch,
      filterConfig,
      organizeData,
      defaultProps,
      organizeName,
      openResetPasswordDialog,
      tableData,
      columns,
      pagination,
      currentChange,
      sizeChange,
      selectDepartment,
      openOrganizationDialog,
      organizationDialog,
      openMemberDialog,
      handleAddOrganization,
      organizationFormRules,
      organizationFormData,
      organizationFormRef,
      superiorOptions,
      memberDialog,
      handleSureMember,
      memberFormData,
      memberFormRules,
      memberFormRef,
      roleOptions,
      resetPasswordDialog,
      handleResetPassword,
      resetPasswordFormRef,
      resetPasswordFormData,
      resetPasswordFormRules,
      organisationOptions,
      memberTitle,
      handleSearchTree,
      handleNodeClick,
      defaultId,
      tree,
      departmentOptions,
      changeStatus,
      canChangeStatus,
      sexOptions,
      organizerColumns,
      organizerTableData,
      organizationTitle,
      handlePrint,
      handleExport,
    };
  },
};
</script>

<style lang="scss">
.organizer-container {
  .el-form-item__label {
    padding: 0 !important;
  }

  .organization-form {
    padding-bottom: 100px;
  }

  .organizer-tree {
    width: 400px;
    margin-right: 16px;
    border-radius: 8px;
    padding: 24px 16px;
    overflow-y: auto;
    .el-tree {
      margin-top: 24px;

      .el-tree-node__content {
        padding: 10px 0;
        height: auto;
        color: var(--text-third-color);
        font-size: 14px;
      }
      .iconfont {
        margin-right: 10px;
        font-size: 14px;
      }
      .is-current > .el-tree-node__content {
        background-color: var(--search-bg-color);
        color: var(--theme-color);
        .el-tree-node__expand-icon {
          color: var(--theme-color);
        }
        .el-tree-node__expand-icon.is-leaf {
          color: transparent !important;
        }
      }

      // .el-tree-node.is-checked > .el-tree-node__content {
      //   background-color: var(--search-bg-color);
      //   color: var(--theme-color);
      // }
    }
  }
  .organizer-empty {
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
